<template>
  <div ref="map-interactive-panel">
    <div style="display: flex;">
      <div :class="['pane-header-container', isMobile]">
        <div class="to-layer-button" v-if="mobileMode" @click="tabActiveName='layers'">
          <div class="el-icon-arrow-left"></div>
        </div>
        <div :class="['pane-header', isMobile]">
          Свойства
        </div>
      </div>
      <div :class="['button-panel', isMobile]">
        <el-dropdown size="medium" @command="$emit('export', $event, selectedObjects, layersSettings)" style="cursor: pointer; color: gray" trigger="click">
            <span>
              Экспорт
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="excel">В excel файл</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div
          class="close-button"
          style="margin: 1px 0 0 10px;"
          @click="$emit('show-tools-panel')"
          v-if="mobileMode"
        >
          <div class="el-icon-close"></div>
        </div>
      </div>
    </div>
    <el-scrollbar
      ref="tools-panel-scrollbar"
      :wrap-style="'height: ' + (this.toolsPanelHeight() - 50) + 'px;'"
      wrap-class="tools-panel-wrap"
      view-style="margin-right: 17px;"
    >
      <el-collapse class="layersAccordion" v-model="openLayersByDefault" v-if="!geometryEdit.mode">
        <el-collapse-item
          class="layerItem"
          :v-if="Object.values(selectedObjects).length !== 0"
          v-for="layer in Object.values(selectedObjects)"
          :key="layer.guid"
          :name="'' + layer.name"
        >
          <template slot="title">
            <div class="layer-title-accordion">
              {{ layer.name }}
            </div>
          </template>
          <el-collapse ref="collapseObjects" class="objectsAccordion" v-model="openObjectsByDefault[layer.guid]">
            <el-collapse-item
              v-for="item in Object.entries(layer.items)"
              :key="item[0]"
              class="objectItem"
              :name="'' + item[0]"
            >
              <template slot="title">
                <el-tooltip placement="top" :content="item[1].label" :open-delay="500">
                  <div
                    class="objectTitle"
                    @contextmenu.prevent="showContextMenu($event, {guid: item[0], ...item[1], layerProperties: layersSettings[layer.guid]})"
                  >
                    {{ item[1].label }}
                  </div>
              </el-tooltip>
              </template>
              <div class="buttonsContainer">
                <div class="itemViewModeSelection" :ref="'buttons_' + item[0]">
                  <el-tooltip content="свойства" placement="top">
                    <div
                      class="smallButton"
                      @click="item[1].viewMode = 'properties'"
                      :style="{ background: item[1].viewMode === 'properties' ? 'url(\'/icon/differentIcons/map-component/options-active.svg\') no-repeat 50% 50%' : 'url(\'/icon/differentIcons/map-component/options.svg\') no-repeat 50% 50%' }"></div>
                  </el-tooltip>
                  <el-tooltip content="геометрия" placement="top" v-if="enableShowGeometrySettings">
                    <div
                      class="smallButton"
                      @click="item[1].viewMode = 'geometry'"
                      :style="{ background: item[1].viewMode === 'geometry' ? 'url(\'/icon/differentIcons/map-component/modify-feature-coordinates-active.svg\') no-repeat 50% 50%' : 'url(\'/icon/differentIcons/map-component/modify-feature-coordinates.svg\') no-repeat 50% 50%' }"></div>
                  </el-tooltip>
                  <el-tooltip content="редактировать геометрию" placement="top">
                    <div
                      class="smallButton"
                      @click="enterEditGeometry(item[1].feature, activeLayers.find(x => x.guid === layer.guid))"
                      v-if="layersSettings[layer.guid] !== undefined
                        && layersSettings[layer.guid].isEditable
                        && ['Registry', 'Query'].includes(layersSettings[layer.guid].source.type)
                        && item[1].viewMode === 'geometry'"
                      style="background: url('/icon/differentIcons/pencil.svg') no-repeat 50% 50%;"></div>
                  </el-tooltip>
                  <el-tooltip content="открыть карточку" placement="top">
                    <div
                      class="smallButton"
                      @click="$emit('open-card', item[1].openCard)"
                      v-if="layersSettings[layer.guid] !== undefined && ['Registry', 'Query'].includes(layersSettings[layer.guid].source.type)"
                      style="background: url('/icon/differentIcons/open-in-window.svg') no-repeat 50% 50%;"></div>
                  </el-tooltip>
                </div>
              </div>
              <div class="objectItemContent">
                <div class="objectItemProperties" v-if="item[1].viewMode === 'properties'">
                  <div
                    class="object-property"
                    v-for="property in Object.entries(item[1].standardCardProperties)"
                    :key="property[0]"
                  >
                    <div class="object-property-label">
                      {{ property[1].name }}
                    </div>
                    <div v-if="property[1].asHtml" class="object-property-value">
                      <span v-html="property[1].value"></span>
                    </div>
                    <div v-else class="object-property-value">
                      {{ property[1].value }}
                    </div>
                  </div>
                </div>
                <div class="objectItemGeometry" v-if="item[1].viewMode === 'geometry'">
                  <vertex-edit-panel
                    :map="map"
                    :layer="layer"
                    :feature="item[1].feature"
                    :v-if="layersSettings[layer.guid]"
                    :srsId="layersSettings[layer.guid].source.nativeCoordinateSystemId"
                    :srsIds="dashboardAndUserCSs()"
                    :vertexNumberingStyle="vertexNumberingStyle"
                    type="view"
                    :ref="'vertex-edit-panel_' + layer.guid + '_' + item[0]"
                  ></vertex-edit-panel>
                  <!-- <el-button @click="saveGeometry(layer.guid, item[0], item[1])" style="margin: 5px 0px 5px 0px;">
                    сохранить
                  </el-button> -->
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
      <div
        v-if="geometryEdit.mode"
        style="padding-left: 5px;">
        <el-row>
          <el-button @click="closeEditGeometry">отмена</el-button>
          <el-button @click="saveEditGeometry">применить</el-button>
        </el-row>
        <vertex-edit-panel
          :map="map"
          v-if="geometryEdit && geometryEdit.layer && geometryEdit.layer.layer && geometryEdit.feature"
          :layer="geometryEdit.layer.layer"
          :feature="geometryEdit.feature"
          :srsId="layersSettings[geometryEdit.layer.guid].source.nativeCoordinateSystemId"
          :srsIds="dashboardAndUserCSs()"
          type="edit"
          :ref="'vertex-edit-panel'"
        ></vertex-edit-panel>
      </div>
    </el-scrollbar>
    <vue-context
      :use-scroll-height="true"
      :useScrollWidth="true"
      class="feature-context"
      ref="context-menu"
      v-slot="{ data }">
      <div class="el-dropdown-menu__item" @click="zoomOnFeature(data)">
        <i class="el-icon-star-on"></i>Перейти к объекту
      </div>
      <div class="el-dropdown-menu__item" @click="findIntersections(data)">
        <i class="el-icon-star-on"></i>Найти пересечения
      </div>
      <div class="el-dropdown-menu__item" v-if="data && ['Registry', 'Query'].includes(data.layerProperties.source.type)" @click="deleteGeometry(data)">
        <i class="el-icon-star-on"></i>Удалить геометрию
      </div>
      <el-dropdown
        class="el-dropdown-menu__item"
        style="display: block;"
        @command="copyGeometry"
        v-if="data && data.layerProperties.isCopyable"
        :show-timeout="50"
      >
        <span>
          <i class="el-icon-star-on"></i>скопировать в
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="layer in editableLayers"
              :key="layer.guid"
              :command="[data, layer.guid]">{{layer.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </span>
      </el-dropdown>
    </vue-context>
  </div>
</template>

<script>
import VertexEditPanel from '@/services/MapEditor/infrastructure/components/VertexEditPanel'
import { APIBasePlugin } from '@/core/infrastructure/api/APIBasePlugin'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'
import MapManager from '@bingo_soft/mapmanager/src/MapManager'

export default {
  name: 'map-interactive-panel',
  components: { VertexEditPanel, VueContext },
  inject: ['toolsPanelHeight', 'dashboardAndUserCSs'],
  props: {
    mobileMode: {
      type: Boolean,
      default: false
    },
    hideLayerButtons: {
      type: Boolean,
      default: false
    },
    interactiveObjects: {
      type: Array,
      default: function () {
        return []
      }
    },
    emptyOpenObjectByDefault: {
      type: Object
    },
    layers: {
      type: Array,
      default: function () {
        return []
      }
    },
    activeLayers: {
      type: Array,
      default: () => []
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    featureMetrics: {
      type: Object,
      default: function () {
        return {
          units: 'meters',
          enable: false,
          enableSwitch: false
        }
      }
    },
    enableShowGeometrySettings: {
      type: Boolean,
      default: true
    },
    map: null,
    activeLayersLength: {
      type: Number,
      default: 0
    },
    showToolsPanel: {
      type: Boolean,
      default: null
    },
    vertexNumberingStyle: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      openLayersByDefault: [],
      openObjectsByDefault: {},
      layersSettings: {},
      selectedObjects: {},
      isLoading: false,
      height: 100,
      featureMetricUnits: 'meters',
      geometryEdit: {
        mode: false,
        layer: null,
        feature: null,
        featureBackup: null
      },
      highlightedFeatures: []
    }
  },
  computed: {
    isMobile: function () {
      return this.mobileMode ? 'mobile' : null
    },
    editableLayers: function () {
      return this.getEditableFieldLayers(this.layers)
    }
  },
  watch: {
    interactiveObjects: {
      handler: async function (newValue, oldValue) {
        this.isLoading = true
        this.$set(this, 'selectedObjects', {})
        await this.generateLayerSettings(newValue)
        await this.setObjectsAccordion(newValue)
        this.fillOpenLayersByDefault(this.selectedObjects)
        this.fillOpenObjectsByDefault(this.selectedObjects)
        this.isLoading = false
      },
      deep: false
    },
    isLoading: {
      handler: function (newValue) {
        this.$emit('interactive-processing', newValue)
      }
    },
    activeLayersLength: {
      handler: function (nv, ov) {
        const layersGuids = this.activeLayers.map(x => x.guid)
        Object.values(this.selectedObjects).forEach(x => {
          if (!layersGuids.includes(x.guid)) {
            this.$delete(this.selectedObjects, x.guid)
          }
        })
      }
    }
  },
  mounted () {
    this.featureMetricUnits = this.featureMetrics.units === 'meters' ? 'м' : 'км'
    // this.editableLayers = this.getEditableFieldLayers(this.layers)
  },
  methods: {
    saveEditGeometry () {
      this.$emit(
        'save-geometry',
        this.geometryEdit.feature,
        this.$refs['vertex-edit-panel'].submit(),
        this.layersSettings[this.geometryEdit.layer.guid]
      )
      this.geometryEdit.mode = false
    },
    closeEditGeometry () {
      this.$refs['vertex-edit-panel'].close()
      this.geometryEdit.mode = false
    },
    enterEditGeometry (geom, layer) {
      this.geometryEdit.feature = geom
      this.geometryEdit.layer = layer
      this.geometryEdit.mode = true
    },
    copyGeometry (a) {
      this.$emit('copy-geometry', a)
    },
    getEditableFieldLayers (layers) {
      let result = []

      layers.forEach((layer) => {
        if (layer.type === 'layer' && layer.properties.source.type === 'Field' && layer.properties.isEditable) {
          result.push(layer)
        }
        /*
        if (layer.type === 'group' && layer.children && Array.isArray(layer.children)) {
          result.push(...this.getEditableFieldLayers(layer.children))
        }*/
      }, this)

      return result
    },
    findIntersections (feature) {
      this.$emit('intersection-feature', feature)
    },
    zoomOnFeature (feature) {
      this.$emit('zoom-on-feature', feature)
    },
    getInterfaceEditorVersion() {
      let version = 1
      let parent = this
      while (parent.$parent) {
        if (parent.$options['_componentTag'] === 'InterfaceViewerV2') {
          version = 2
        }
        parent = parent.$parent
      }
      return version
    },
    showContextMenu (event, item) {
      let customEvent = {}
      switch (this.getInterfaceEditorVersion()) {
        default:
        case 1:
          if (this.isFullscreen) {
            customEvent = event
          } else {
            customEvent.clientX = event.layerX + 55
            customEvent.clientY = event.layerY + 30
            customEvent.target = event.target
          }
          break;
        case 2:
          customEvent = event
          break;
      }
      this.$refs['context-menu'].open(customEvent, item)
    },
    async generateLayerSettings (selectedItems) {
      this.$set(this, 'layersSettings', {})
      for (let i = 0; i < selectedItems.length; i++) {
        let item = selectedItems[i]
        let layer = this.findLayerByGuid(this.layers, item.layerGuid)
        if (layer === false) {
          console.log('Ошибка генерации настроек слоя');
        }
        let currentLayerSettings = {
          guid: layer.guid,
          interactive: JSON.parse(JSON.stringify(layer.properties.interactive)),
          name: layer.name,
          source: layer.properties.source,
          isEditable: layer.properties.isEditable,
          isCopyable: layer.properties.isCopyable
        }
        if (currentLayerSettings.interactive.card) {
          if (layer.properties.source.type === 'WFS') {
            currentLayerSettings.interactive.standardCard.fields.forEach((field) => {
              field.id = field.name
            })
          }
        }


        let properties = item.item.getFeature().getProperties()
        // если источник слоя - WFS и данными тянутся плагином
        if (layer.properties.source.type === 'WFS' &&
          layer.properties.interactive.standardCard.type === 'plugin' &&
          layer.properties.interactive.standardCard.plugin !== null) {
          let additionalProperties = await APIClient.shared.request(
            new APIBasePlugin.BasePluginQuery(
              layer.properties.interactive.standardCard.plugin,
              {
                object_id: properties.object_id,
                row_id: properties.row_id,
                layer_id: this.getParamFromUrl(layer.properties.source.externalURL, 'typeName')
              }
            )
          )
          if (additionalProperties === -1) {
            this.$message({
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: 'Сервер не отвечает'
            })
          } else {
            Object.entries(additionalProperties[0]).forEach(function (item) {
              currentLayerSettings.interactive.standardCard.fields.splice(
                currentLayerSettings.interactive.standardCard.fields.length,
                0,
                { id: '' + item[0], label: '' + item[1].name }
              )
              properties[item[0]] = item[1].value
            })
          }
          currentLayerSettings.interactive.standardCard.id = 'row_id'
        }
        this.$set(this.layersSettings, layer.guid, currentLayerSettings)
      }
    },
    getParamFromUrl (url, paramName) {
      let startIndex = url.indexOf(paramName + '=') + paramName.length + 1
      let endIndex = url.indexOf('&', startIndex + 1)
      return url.substring(
        startIndex,
        endIndex !== -1 ? endIndex : url.length
      )
    },
    findLayerByGuid(layers, guid) {
      return layers.find(x => x.guid === guid)
      /*
      let result = false
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'layer' && layers[i].guid === guid) {
          return layers[i]
        }
        if (layers[i].type === 'group' && layers[i].children.length > 0) {
          result = this.findLayerByGuid(layers[i].children, guid)
          if (result !== false) {
            return result
          }
        }
      }
      return result
      */
    },
    async setObjectsAccordion (items) {
      this.$set(this, 'selectedObjects', {})
      Object.entries(this.layersSettings).forEach((layerSettings) => {
        this.$set(this.selectedObjects, layerSettings[1].guid, {
          guid: layerSettings[1].guid,
          name: layerSettings[1].name,
          items: {}
        })
      })

      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        let layer = this.layersSettings[item.layerGuid]
        if (typeof layer === 'undefined') {
          console.log('Пустые настройки слоя! Ошибка')
          return
        }

        const itemGuid = layer.source.type === 'Registry' ? item.properties.guid : this.generateGuid()

        //for vectortile layers
        const keys = Object.keys(item.properties)
        for (let j = 0; j < keys.length; j++) {
          try {
            item.properties[keys[j]] = JSON.parse(item.properties[keys[j]])
          } catch {}
        }

        let name = item.properties[layer.interactive.standardCard.name]

        if (Array.isArray(name)) {
          name = name.map(x => x.name).join(', ')
        } else if (name && typeof name !== 'string') {
          name = '' + name
        }

        this.$set(this.selectedObjects[layer.guid].items, itemGuid, {
          label: name,
          standardCardProperties: [],
          viewMode: 'properties',
          feature: item.item,
          featureType: item.featureType,
          //сюда длину ширину
          openCard: {
            settings: layer.interactive.card.card,
            properties: item.properties
          }
        })

        if (layer.source.type === 'WFS' &&
          layer.interactive.standardCard.type === 'plugin' &&
          layer.interactive.standardCard.plugin !== null) {
          let additionalProperties = await APIClient.shared.request(
            new APIBasePlugin.BasePluginQuery(
              layer.interactive.standardCard.plugin,
              {
                object_id: item.properties.object_id,
                row_id: item.properties.row_id,
                layer_id: this.getParamFromUrl(layer.source.externalURL, 'typeName')
              }
            )
          )
          if (additionalProperties === -1) {
            this.$message({
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: 'Сервер не отвечает'
            })
          } else {
            if (!item.properties) {
              item.properties = {}
            }
            Object.entries(additionalProperties[0]).forEach(function (itemValue) {
              item.properties[itemValue[0]] = itemValue[1].value
            })
          }
        }
        layer.interactive.standardCard.fields.forEach((field) => {
          const id = layer.source.type === 'OGCFeatures' ? field.name : field.id
          const fieldId = layer.source.type === 'Registry' ? 'attr_' + id + '_' : id
          let value = item.properties[fieldId]
          if (value !== undefined) {
            if (['address_field', 'address_multi_field'].includes(field.entity_type_id)) {
              let names = []
              value.forEach((item) => {
                names.push(item.name)
              })
              value = names.join('; ')
            }
            if (['xref_field', 'xref_multi_field'].includes(field.entity_type_id)) {
              if (Array.isArray(value)) {
                let names = []
                value.forEach((item) => {
                  names.push(item.name)
                })
                value = names.join(', ')
              }
            }
            if (field.entity_type_id === 'date_field') {
              if (value !== null) {
                let date = new Date(value)
                value = this.addPrevSymbolsToString(date.getDate(), 2, '0') +
                  '.' + this.addPrevSymbolsToString(date.getMonth() + 1, 2, '0') +
                  '.' + date.getFullYear()
              }
            }
            if (field.entity_type_id === 'datetime_field') {
              if (value !== null) {
                let date = new Date(value)
                value = this.addPrevSymbolsToString(date.getDate(), 2, '0') +
                  '.' + this.addPrevSymbolsToString(date.getMonth() + 1, 2, '0') +
                  '.' + date.getFullYear() +
                  ' ' + this.addPrevSymbolsToString(date.getHours(), 2, '0') +
                  ':' + this.addPrevSymbolsToString(date.getMinutes(), 2, '0') +
                  ':' + this.addPrevSymbolsToString(date.getSeconds(), 2, '0')
              }
            }
            if (field.entity_type_id === 'boolean_field') {
              if (value) {
                value = 'Да'
              } else {
                value = 'Нет'
              }
            }
          }
          this.selectedObjects[layer.guid].items[itemGuid].standardCardProperties.splice(0, 0, {
            name: field.label,
            value: value,
            asHtml: field.asHtml
          })
        })
      }
    },
    deleteGeometry (item) {
      this.$confirm('Вы уверены что хотите удалить выбранную геометрию', 'Внимание', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then(() => {
        this.$emit('delete-geometry', item.guid)
      })
    },
    resetOpenObjectsByDefault() {
      this.$set(this, 'openObjectsByDefault', JSON.parse(JSON.stringify(this.emptyOpenObjectByDefault)))
    },
    fillOpenLayersByDefault (objects) {
      this.openLayersByDefault.splice(0, this.openLayersByDefault.length)
      if (Object.keys(objects).length === 1) {
        this.openLayersByDefault.push(objects[Object.keys(objects)[0]].name)
      }
    },
    fillOpenObjectsByDefault (objects) {
      this.resetOpenObjectsByDefault()
      if (Object.keys(objects).length === 1) {
        const key = (Object.keys(objects))[0]
        if ((Object.keys(objects[key].items)).length === 1) {
          const objectKey = (Object.keys(objects[key].items))[0]
          this.openObjectsByDefault[key].splice(0, 0, objectKey)
        }
      }
    },
    saveGeometry (layerGuid, itemId, item) {
      let vertexEditPanel = this.$refs['vertex-edit-panel_' + layerGuid + '_' + itemId][0]
      this.$emit('save-geometry', item, vertexEditPanel.submit(), this.layersSettings[layerGuid])
    },
    addPrevSymbolsToString (string, length, symbol) {
      if (typeof string !== 'string') {
        string = string.toString()
      }
      while (string.length < length) {
        string = symbol + string
      }
      return string
    },
    highlightFeature () {
      // Если компонент "дерево слоев" скрыт - удаляет стиль у выделенных фич
      if (!this.showToolsPanel) {
        this.highlightedFeatures.forEach((feature) => {
          MapManager.unhighlightFeature(feature)
        })
        this.highlightedFeatures = []
        return
      }
      // Формирует массив раскрытых фич из всех слоев
      let selectedFeatures = []
      for (let layerGuid in this.openObjectsByDefault) {
        this.openObjectsByDefault[layerGuid].forEach((featuresGuid) => {
          let selectedLayer = Object.values(this.selectedObjects).filter(x => x.guid === layerGuid)[0]
          selectedFeatures.push(selectedLayer.items[featuresGuid].feature)
        })
      }
      // Формирует массив фич, у которых нужно удалить стиль
      let featuresForUnHighlight = this.highlightedFeatures.filter(x => !selectedFeatures.includes(x))
      featuresForUnHighlight.forEach((feature) => {
        MapManager.unhighlightFeature(feature)
        this.highlightedFeatures.splice(this.highlightedFeatures.indexOf(feature), 1)
      })
      // Формирует массив фич, у которых нужно добавить стиль выделения
      let featuresForHighlighting = selectedFeatures.filter(x => !this.highlightedFeatures.includes(x))
      featuresForHighlighting.forEach((feature) => {
        MapManager.highlightFeature(feature)
        this.highlightedFeatures.push(feature)
      })
    }
  }
}
</script>

<style scoped>
  .radio-group /deep/ .el-radio-button__inner {
    padding: 3px;
  }
  .feature-context {
    padding: 0;
  }
  .tools-panel-wrap {
    overflow-x: hidden;
    margin-right: 17px;
    margin-bottom: 0px;
  }
  .tools-panel-scrollbar /deep/ .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
  .to-layer-button {
    margin: 9px;
    cursor: pointer;
  }
  .buttonsContainer {
    position: relative;
    top: -30px;
  }
  .pane-header-container {
    display: inline-flex;
    flex-grow: 50;
  }
  .pane-header {
    padding: 10px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    flex-grow: 50;
  }
  .button-panel {
    flex-grow: 1;
    align-self: flex-end;
    top: -3px;
    position: relative;
    padding: 10px;
  }
  .mobile.button-panel {
    margin: 4px 0 0 0;
  }
  .close-button {
    float: right;
    cursor: pointer;
    margin: 11px 10px 0;
  }
  .tools-panel-scrollbar {
    height: calc(100% - 60px);
  }
  .layersAccordion /deep/ .el-collapse-item__content {
    border-left-color: #A5AEB6;
    border-left-style: solid;
    border-left-width: 1px;
    padding-bottom: 0;
  }
  .layersAccordion {
    margin: 10px;
  }
  .layerItem {
    margin-left: 10px;
  }

  .el-collapse {
    border: none;
  }
  .el-collapse /deep/ .el-collapse-item__wrap {
    border: none;
    overflow: visible;
  }
  .el-collapse /deep/ .el-collapse-item__arrow {
    left: -26px;
    position: absolute;
  }
  .el-collapse /deep/ .el-collapse-item__header {
    left: 20px;
    position: relative;
    height: 36px;
    line-height: 36px;
    border: none;
    white-space: nowrap;
    font-weight: 400;
  }
  .layer-title-accordion {
    overflow: hidden;
    width: 99%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .objectsAccordion {
    margin-left: 10px;
  }
  .objectsAccordion /deep/ .el-collapse-item__content {
    padding-bottom: 5px;
  }
  .objectItem /deep/ .el-collapse-item__content {
    border-left-style: hidden;
    padding-bottom: 0;
  }
  .objectTitle {
    width: calc(100% - 72px);
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .itemViewModeSelection {
    float: right;
    width: max-content;
    height: 24px;
    position: absolute;
    right: -16px;
  }
  .smallButton {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    margin: 0;
  }
  .object-property {
    left: 10px;
    position: relative;
    padding: 10px;
  }
  .object-property:nth-child(2) {
    background-color: #F4F5F6;
  }
  .object-property-label {
    color: #6E7B87;
    height: 16px;
    font-size: 15px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .object-property-value {
    font-size: 15px;
    line-height: 18px;
  }
</style>
